<template>
<div class="background">
  <div class="breadcrumb" @click="$router.push('/ChainList')">
    供应商列表   >
  </div>
  <div class="infTop">
    <div class="infLeft">
      <!--信息自动获取-->
      <div>
        <div class="avatar"><img :src="company.avatar" alt="" style="height: 100px;width: 100px;"></div>
        <div class="infLeftRight">
          <div class="name">{{company.name}}</div>
          <!--标签自动渲染-->
          <div>
            <div class="tag" v-for="(tag,i) in company.tags">
              <div>
                {{tag}}
              </div>
            </div>
          </div>
          <div class="inf">
            <p>企业规模：{{company.size}}人</p>
            <p>注册资金：{{company.bankroll}}万元</p>
            <p>企业网址：{{company.website}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="infRight">
      <p>基本信息</p>
      <div>
<!--        调用element-ui超过150px的高度自动显示滚动条-->
          <el-scrollbar max-height="160px">
            <p class="bicInf">{{company.basicInf}}</p>
          </el-scrollbar>

      </div>
    </div>
  </div>

  <div class="InfBottom">
    <div class="pageSwitch">
      <div>
        <a @click="ChangeType(1)">参与平台项目</a>
        <i v-show="changeType.type === 1"></i>
      </div>
      <div>
        <a @click="ChangeType(2)">产品案例</a>
        <i v-show="changeType.type === 2"></i>
      </div>
      <div>
      <a @click="ChangeType(3)">自身优势</a>
      <i v-show="changeType.type === 3"></i>
    </div>
      <div>
      <a @click="ChangeType(4)">证书</a>
      <i v-show="changeType.type === 4"></i>
      </div>
      <button @click="this.dialogVisible = true">联系服务方</button>
    </div>
    <div class="project" v-show="changeType.type === 1">
      <div v-for="(item,i) in projects">
        <div style="float: left;margin-right: 30px">
          <div class="project_pic">
            <img :src="item.pic" alt="" style="width: 300px; height: 200px">
          </div>
          <div class="project_name">
            {{item.projectName}}
          </div>
        </div>
      </div>
    </div>
    <div class="cases" v-show="changeType.type === 2">
      <div v-for="(item,i) in projects">
        <div style="float: left;margin-right: 30px">
          <div class="project_pic">
            <img :src="item.pic" alt="" style="width: 300px; height: 200px">
          </div>
          <div class="project_name">
            {{item.projectName}}
          </div>
        </div>
      </div>
    </div>
    <div class="strength" v-show="changeType.type === 3">
      <div v-for="(item,i) in projects">
        <div style="float: left;margin-right: 30px">
          <div class="project_pic">
            <img :src="item.pic" alt="" style="width: 300px; height: 200px">
          </div>
          <div class="project_name">
            {{item.projectName}}
          </div>
        </div>
      </div>
    </div>
    <div class="certificate" v-show="changeType.type === 4">
      <div v-for="(item,i) in projects">
        <div style="float: left;margin-right: 30px">
          <div class="project_pic">
            <img :src="item.pic" alt="" style="width: 300px; height: 200px">
          </div>
          <div class="project_name">
            {{item.projectName}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="dialogVisible" width="595px" >
    <div class="dialog-header">
      <i>联系服务商</i>
    </div>
    <div class="dialog-body">
      <div class="content">
        <span>服务商:</span><i>淄博华彩电子科技有限公司</i><br>
        <span>联系电话:</span><i>15092365989 0533-8170734</i><br>
        <span>联系邮箱:</span><i>langyungongyesheji@163.com</i><br>
        <span>服务商地址:</span><i>山东省淄博市张店区柳泉路139号齐鲁电商谷B座</i>
      </div>
      <div class="qr-code">
      </div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { ArrowRight } from '@element-plus/icons'
export default {
  name: "Company",
  data(){
    return{
      dialogVisible:false,

      getInfoo:{
        id:''
      },
      changeType:{
        id:'',
        type: 1,
      },
      company:{
        id:'',
        avatar:'',
        name:'',
        size:'',
        bankroll:'',
        tags:[],
        website:'',
        basicInf:'',
      },
      projects:[]
    }
  } ,
  mounted() {
    this.getInfoo.id = this.$route.query.companyId;
    this.changeType.id = this.$route.query.companyId;
    this.getInfo();
    this.ChangeType(1);
  },
  methods:{
    getInfo(){
      //使用前将其转换为JSON
      this.$http({
        method: 'post',
        url: '/supplyChain/supplyDetail/company',
        data: this.getInfoo,
      }).then(res => {
        this.company = res.data;
      })
    },
    ChangeType(index){
      this.changeType.type = index;
      this.$http({
        method: 'post',
        url: '/supplyChain/supplyDetail/cases',
        data: this.changeType,
      }).then(res => {
        this.projects = res.data;
      })
    }
  },
  components:{
    ArrowRight,
  }

}
</script>

<style scoped>
.background{
  background-color: rgb(251,249,248);
  padding-bottom: 85px;
}

.breadcrumb{
  width: 100%;
  height: 98px;
  margin-left: 180px;
  padding-top: 52px;
  font-size:14px;
  color: #8f8c8e;
  cursor: pointer;
}

.breadcrumb::before{
  content: '当前位置: ';
  font-size: 14px;
  color: #212121;
  font-weight: 500;
}

.breadcrumb::after{
  content: '  淄博华彩电子科技有限公司';
  color: #ea3d44;
}

.infTop{
  height: 300px;
  margin-bottom: 50px
}

.infTop .infLeft{
  float: left;
  width: 757px;
  height: 300px;
  margin-left: 180px;
  padding-top: 50px;
  border-radius: 10px;
  background-color: white;
  box-shadow: #d4d2d2 0 5px 10px;
  -webkit-box-shadow: #d4d2d2 0 0 10px;
  -moz-box-shadow: #d4d2d2 0 0 10px;
}

.infLeft .infLeftRight{
  margin-left: 190px;
  width: 535px;
  height: 250px
}

.infLeft .avatar{
  float: left;
  margin-left: 50px;
  height: 100px;
  width: 100px;
  border-radius: 10px;
}

.infLeft .name{
  color: black;
  font-size: 34px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 15px;
}

.infLeft .tag{
  padding: 7px 18px 8px;
  background-color: rgb(248,248,248);
  margin-right: 10px;
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: rgb(148,148,148);
}

.infLeft .inf{
  padding-top: 70px;
  font-size: 18px;
  font-weight: 400;
  color: black;
}

.infLeft .inf p:nth-child(1){
  float: left;
  padding-right: 50px;

}

.infLeft .inf p:nth-child(2){
  /*float: left;*/
}

.infLeft .inf p:nth-child(3){
  float: left;
  margin-top: 15px;

}

.infTop .infRight{
  float: left;
  width: 757px;
  height: 300px;
  padding-top: 40px;
  padding-left: 50px;
  margin-left: 50px;
  border-radius: 10px;
  color: black;
  background-color: white;
  box-shadow: #d4d2d2 0 5px 10px;
  -webkit-box-shadow: #d4d2d2 0 0 10px;
  -moz-box-shadow: #d4d2d2 0 0 10px;
}

.infRight p{
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.infRight .bicInf {
  font-size: 16px;
  font-weight: 300;
  margin-right: 50px;
}

/*清除掉组件的内部样式*/
::v-deep .el-dialog{
  border-radius: 5px;
  margin-top: 30vh!important;
}

::v-deep .el-dialog__header{
  padding: 0;
}

::v-deep .el-icon-close:before{
  color: #ffffff;
}

::v-deep .el-dialog__body{
  width: 595px;
  height: 255px;
  padding: 0;
  border-radius: 5px;
}

::v-deep .el-dialog__footer{
  padding: 0;
}

.dialog-header{
  width: 595px;
  height: 52px;
  background-color: #ec414d;
  line-height: 52px;
  margin-bottom: 30px;
  border-radius: 5px 5px 0 0;
}

.dialog-header i:nth-of-type(1){
  margin-left: 27px;
  font-size: 18px;
  color: #ffffff;
}

.dialog-body span{
  display: inline-block;
  width: 74px;
  margin-left:25px;
  margin-bottom:14px;
  font-size: 14px;
  color: #212121;
  text-align-last: justify;
}

.dialog-body .content{
  float: left;
  margin-right: 56px;
}

.dialog-body .qr-code{
  float: left;
  width: 115px;
  height: 115px;
  background-image: url("../../images/QRCode.png");
  background-size: 100%;
}





.InfBottom{
  width: 1564px;
  height: 418px;
  background-color: #ffffff;
  margin: 0 auto;
  /*background-color: pink;*/
  padding-top: 50px;
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: #d4d2d2 0 5px 10px;
  -webkit-box-shadow: #d4d2d2 0 0 10px;
  -moz-box-shadow: #d4d2d2 0 0 10px;
  padding-bottom: 50px;
}

.InfBottom .pageSwitch{
  width: 1288px;
  height: 40px;
  /*background-color: #1ea1e0;*/
  border-bottom: 1px solid #f1e3e3;
  margin: 0 auto 50px;
}

.InfBottom .pageSwitch div{
    float: left;
    width: 128px;
    height: 100%;
    font-size: 21px;
    color: #212121;
  text-align: center;
  margin-right: 85px;
  cursor: pointer;
}

.InfBottom .pageSwitch button{
  float:right;
  width: 98px;
  height: 36px;
  font-size: 14px;
  color: #ffffff;
  margin-top: -6px;
  background-color: #ed414d;
  border-radius: 3px;
}

.pageSwitch i{
  display: block;
  width: 100%;
  height: 6px;
  margin-top: 5px;
  background-color: #ec414d;
  border-radius: 3px;
}

.InfBottom .project{
  width: 1320px;
  height: 236px;
  /*background-color: #1ea1e0;*/
  margin-left: 140px;
}

.InfBottom .project .project_pic{
  width: 300px;
  height: 200px;

  margin-bottom: 15px;
}

.InfBottom .project .project_name{
  color: #212121;
  font-size: 18px;
}

.InfBottom .cases{
  width: 1320px;
  height: 236px;
  /*background-color: #1ea1e0;*/
  margin-left: 140px;
}

.InfBottom .cases .cases_pic{
  width: 300px;
  height: 200px;
  background-color: red;
  margin-bottom: 15px;
}

.InfBottom .cases .cases_name{
  color: #212121;
  font-size: 18px;
  background-color: #985f0d;
}
</style>